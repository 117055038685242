<template>
    <div class="page-wrapper">
        <Spinner v-if="BALANCE_LOADING" :fullScreen="true" />

        <PageSection v-else>
            <div class="col-md-8 offset-md-2">
                <h1>{{ t('prepay_overview.summary_title') }}</h1>

                <p v-if="PREPAY_SUMMARY">
                    {{ t('prepay_overview.summary_balance', { balance: PREPAY_SUMMARY.minuteBalance }) }}
                    <router-link :to="{ name: 'Route_PrepayDetails' }">
                        {{ t('prepay_overview.summary_details_link') }}
                    </router-link>
                </p>
                <p v-else>
                    {{ t('prepay_overview.summary_balance', { balance: 0 }) }}
                </p>
            </div>

            <div v-if="!!PAYMENT_ERROR" class="col-md-8 offset-md-2">
                <p v-if="PAYMENT_ERROR == 'authorization'" class="alert alert-danger" style="font-size: 14px;">
                    {{ t('prepay_overview.error_authorization') }}
                    {{ t('prepay_overview.error_try_again') }}
                </p>
                <p v-if="PAYMENT_ERROR == 'cancelled'" class="alert alert-danger" style="font-size: 14px;">
                    {{ t('prepay_overview.error_cancelled') }}
                    {{ t('prepay_overview.error_try_again') }}
                </p>
                <p v-if="PAYMENT_ERROR == 'capture'" class="alert alert-danger" style="font-size: 14px;">
                    {{ t('prepay_overview.error_capture') }}
                    {{ t('prepay_overview.error_try_again') }}
                </p>
                <p v-if="PAYMENT_ERROR == 'failed'" class="alert alert-danger" style="font-size: 14px;">
                    {{ t('prepay_overview.error_failed') }}
                    {{ t('prepay_overview.error_try_again') }}
                </p>
            </div>
            <PrepaymentInitiationForm @onSubmit="INITIATE_PAYMENT($event)" :disableForm="!!DINTERO_PAYMENT_ID" />
        </PageSection>

        <PageSection v-if="DINTERO_PAYMENT_ID">
            <DinteroEmbeddedCheckout
                :paymentId="DINTERO_PAYMENT_ID"
                :heading="t('prepay_overview.checkout_title')"
                :scrollIntoView="true"
            />
        </PageSection>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRoute } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import Spinner from '../../components/Spinner.vue';
    import PrepaymentInitiationForm from './PrepaymentInitiationForm.vue';
    import DinteroEmbeddedCheckout from '../../components/DinteroEmbeddedCheckout/DinteroEmbeddedCheckout.vue';
    import PageSection from '../../components/PageSection.vue';

    const store = useStore();
    const route = useRoute();
    const { t } = useI18n();

    store.dispatch('pagePrepayOverview/ON_PAGE_LOAD');
    store.dispatch('balancePrepay/LOAD_BALANCE');

    const BALANCE_LOADING = computed(() => store.getters['balancePrepay/LOADING']);
    const PREPAY_SUMMARY = computed(() => store.getters['balancePrepay/PREPAY_SUMMARY']);
    const DINTERO_PAYMENT_ID = computed(() => store.getters['pagePrepayOverview/DINTERO_PAYMENT_ID']);
    const CCID = computed(() => store.getters['appStateStorage/CCID']);

    const INITIATE_PAYMENT = ({ email, minutes, newsletterConsent }) => {
        let ccid = CCID.value;
        store.dispatch('pagePrepayOverview/INITIATE_PAYMENT', { email, minutes, ccid });
        store.dispatch('customerPreferences/UPDATE_CUSTOMER_PREFERENCES', { email, newsletterConsent });
    };

    const PAYMENT_ERROR = computed(() => route.query.error || '');
</script>

<template>
    <Spinner v-if="INITIATING_PAYMENT" :overlayParent="true" />

    <ErrorMessage :message="INITIATION_ERROR" />

    <form novalidate @submit.prevent="submitForm()" class="col-sm-8 offset-sm-2 text-center">
        <div class="mb-3">
            <p class="text-start">
                <span class="fw-bold">{{ t('prepay_overview.form_minutes') }}</span>
                <br/>
                <span>{{ t('prepay_overview.form_minutes_terms') }}</span>
            </p>
            
            <div class="ms-3" v-for="tariff of TARIFFS" :key="tariff.minutes" style="text-align: left;">
                <input
                    type="radio"
                    :id="tariff.minutes"
                    :value="tariff.minutes"
                    v-model="v$.minutes.$model"
                    :disabled="disableForm"
                />
                <label :for="tariff.minutes" :disabled="disableForm"
                    ><span class="ms-1">{{ tariff.minutes }}</span>
                    <span v-if="tariff.defaultValue" class="ms-1">{{
                        t('prepay_overview.form_minutes_option_default')
                    }}</span>
                    <span v-else class="ms-1">{{ t('prepay_overview.form_minutes_option') }}</span></label
                >
            </div>

            <ValidationError
                :formModel="v$.minutes"
                type="required"
                :message="t('prepay_overview.minutes_val_required')"
            />

            <p class="mt-3 mt-sm-4 mb-3 mb-sm-4 text-start">
                {{ t('prepay_overview.form_total', { total: getTotal(v$.minutes.$model) }) }}
            </p>
        </div>

        <p class="fw-bold text-start">{{ t('prepay_overview.form_email') }}</p>
        <div class="mb-4">
            <TextInput :field="v$.email" :placeholder="t('prepay_overview.email_hint')" :disabled="disableForm" />
            <ValidationError :formModel="v$.email" type="required" :message="t('prepay_overview.email_val_required')" />
            <ValidationError :formModel="v$.email" type="email" :message="t('prepay_overview.email_val_invalid')" />

            <div v-if="!newsletterAccepted" class="mt-1 text-start">
                <CheckboxInput
                    :field="v$.newsletterConsent"
                    :label="t('prepay_overview.subscribe_to_newsletter')"
                    :id="newsletterCheckbox"
                    :disabled="disableForm"
                />
            </div>
        </div>

        <button class="btn btn-primary msd-form-submit" :disabled="disableForm">
            {{ t('prepay_overview.submit') }}
        </button>
    </form>
    <div v-if="!disableForm" class="mt-3 mb-3">
        <img :src="postPaymentEngineLogo" class="msd-payment-logo" />
    </div>
</template>

<script setup>
    import { computed, reactive, ref } from 'vue';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n';
    import useVuelidate from '@vuelidate/core';
    import { required, email } from '@vuelidate/validators';
    import TextInput from '../../components/VuelidateForms/TextInput.vue';
    import CheckboxInput from '../../components/VuelidateForms/CheckboxInput.vue';
    import ValidationError from '../../components/VuelidateForms/ValidationError.vue';
    import Spinner from '../../components/Spinner.vue';
    import ErrorMessage from '../../components/ErrorMessage.vue';

    const props = defineProps({
        disableForm: { type: Boolean, required: false, default: false }
    });
    const emit = defineEmits(['onSubmit']);

    const store = useStore();
    const { t } = useI18n();

    const TARIFFS = store.getters['balancePrepay/TARIFFS'];

    const INITIATING_PAYMENT = computed(() => store.getters['pagePrepayOverview/INITIATING_PAYMENT']);
    const INITIATION_ERROR = computed(() => store.getters['pagePrepayOverview/INITIATION_ERROR']);
    const postPaymentEngineLogo = store.getters['appSettings/POST_PAYMENT_ENGINE_LOGO'];
    const newsletterAccepted = store.getters['customerPreferences/NEWSLETTER_CONSENT'];

    const formValues = reactive({
        minutes: store.getters['balancePrepay/DEFAULTTARIFF'],
        email: store.getters['customerPreferences/RECENT_MAILBOX'],
        newsletterConsent: !newsletterAccepted
    });
    const validationRules = computed(() => ({
        minutes: { required },
        email: { required, email },
        newsletterConsent: {}
    }));
    const v$ = useVuelidate(validationRules, formValues);

    const submitForm = () => {
        v$.value.$touch();
        if (!v$.value.$invalid) {
            emit('onSubmit', formValues);
        }
    };

    const getTotal = (min) => {
        var tot = TARIFFS.find((x) => x.minutes === min);
        return tot.price;
    };
</script>